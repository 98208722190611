(function($R)
{
    $R.lang['en'] = {
        "format": "Format",
        "image": "Image",
        "file": "File",
        "link": "Link",
        "bold": "Bold",
        "italic": "Italic",
        "deleted": "Strikethrough",
        "underline": "Underline",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Lists",
        "link-insert": "Insert Link",
        "link-edit": "Edit Link",
        "link-in-new-tab": "Open link in new tab",
        "unlink": "Unlink",
        "cancel": "Cancel",
        "close": "Close",
        "insert": "Insert",
        "save": "Save",
        "delete": "Delete",
        "text": "Text",
        "edit": "Edit",
        "title": "Title",
        "paragraph": "Normal text",
        "quote": "Quote",
        "code": "Code",
        "heading1": "Heading 1",
        "heading2": "Heading 2",
        "heading3": "Heading 3",
        "heading4": "Heading 4",
        "heading5": "Heading 5",
        "heading6": "Heading 6",
        "filename": "Name",
        "optional": "optional",
        "unorderedlist": "Unordered List",
        "orderedlist": "Ordered List",
        "outdent": "Outdent",
        "indent": "Indent",
        "horizontalrule": "Line",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Rich text editor",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);