function isScrolledIntoView(elem) {
  var docViewTop = $(window).scrollTop();
  var docViewBottom = docViewTop + $(window).height();

  var elemTop = $(elem).offset().top;
  var elemBottom = elemTop + $(elem).height();

  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

export function initializeHomepageCounters() {
  if ($('.counter-row').length <= 0 || $('.counter-row').hasClass('counter-opened')) { return; }

  if (isScrolledIntoView($('.counter-row'))) {
      $('.counter-row').addClass('counter-opened');

      $('.counter').each(function() {
        var $this = $(this),
              countTo = $this.attr('data-count');

          $({ countNum: $this.text() }).animate({
              countNum: countTo
          }, {
              duration: 4000,
              easing: 'linear',
              step: function() {
                  $this.text(Math.floor(this.countNum));
              },
              complete: function() {
                  $this.text(this.countNum);
              }
          });
      });

      $('.counter-currency').each(function() {
        var $this = $(this),
              countTo = $this.attr('data-count');

          $({ countNum: $this.text() }).animate({
              countNum: countTo
          }, {
              duration: 4000,
              easing: 'linear',
              step: function() {
                $this.text(Math.floor(this.countNum).toLocaleString('fr'));
              },
              complete: function() {
                $this.text(this.countNum.toLocaleString('fr'));
              }
          });
    });
  }
}