export function toggleSupportFields() {
  var supportFields = $('.support-information');
  if ($(this).is(':checked')) {
    supportFields.map(function() {
      $(this).removeClass('d-none');
    });
  } else if ($(this).is(':not(:checked)')) {
    supportFields.map(function() {
      if (!$(this).hasClass('d-none')) {
        $(this).addClass('d-none');
      }
    });
    $('#funding_application_support_organization').val('');
    $('#funding_application_support_contact').val('');
    $('#funding_application_support_type').prop('selectedIndex', 0);
  }
}
