export function addJob() {
  var newJobForm = $("#new-job-form").html()
  var countInput = $('input').filter(function() { return this.id.match(/jobs_attributes/);}).length
  newJobForm = newJobForm.replace ( "funding_application[jobs_attributes][0][function]",`funding_application[jobs_attributes][${countInput}][function]` )
  newJobForm = newJobForm.replace ( "funding_application[jobs_attributes][0][contract_type]", `funding_application[jobs_attributes][${countInput}][contract_type]` )
  newJobForm = newJobForm.replace ( "funding_application_jobs_attributes_0_function", `funding_application_jobs_attributes_${countInput}_function` )
  newJobForm = newJobForm.replace ( "funding_application_jobs_attributes_0_contract_type", `funding_application_jobs_attributes_${countInput}_contract_type`)
  $("#fields").append(newJobForm);
}

export function deleteJob() {
  $(this).closest('.job').remove();
}
