export function addOptionalDocumentCompany() {
  var newOptionalDocumentCompanyFields = $("#new-optional-document-company-fields").html()
  var idToReplace = parseInt($('#new-optional-document-company-fields :input')[0].id.match(/\d+/)[0])
  var newId = $("input:file").length
  newOptionalDocumentCompanyFields = newOptionalDocumentCompanyFields.replace ( new RegExp(`${idToReplace}`, 'g'),`${newId}` )
  $("#fields-optional-documents").append(newOptionalDocumentCompanyFields);
}

export function addOptionalDocumentNonprofitAndIndividual() {
  var newOptionalDocumentNonprofitAndIndividualFields = $("#new-optional-document-nonprofit-and-individual-fields").html()
  var idToReplace = parseInt($('#new-optional-document-nonprofit-and-individual-fields :input')[0].id.match(/\d+/)[0])
  var newId = $("input:file").length
  newOptionalDocumentNonprofitAndIndividualFields = newOptionalDocumentNonprofitAndIndividualFields.replace ( new RegExp(`${idToReplace}`, 'g'),`${newId}` )
  $("#fields-optional-documents").append(newOptionalDocumentNonprofitAndIndividualFields);
}

export function deleteOptionalDocument() {
  $(this).closest('.optional-document').remove();
}
