export function toggleBusinessFormationFields() {
  var formationFields = $('.business-formation');
  if ($(this).is(':checked')) {
    formationFields.map(function() {
      $(this).removeClass('d-none');
    });
  } else if ($(this).is(':not(:checked)')) {
    formationFields.map(function() {
      $(this).addClass('d-none');
    });
    $('#funding_application_user_business_formation_place').val('');
    $('#funding_application_user_business_formation_year').val('');
  }
}
