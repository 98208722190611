// add file name on label 
$(document).ready(function () {
  $('body').on('change', '.custom-file-input', function() {
    if ($(this)[0].files[0] !== undefined) {
      $(this)
        .siblings(".custom-file-label")
        .html($(this)[0].files[0].name);
    }
  });
});  
