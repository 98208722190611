export function displayBeneficialOwnerSection() {
  var beneficialOwnersRows = $('.beneficial-owners');
  var valuesWithoutBeneficialOwners = ['individual', 'eirl', 'eurl']
  var value = $('#funding_application_entity_attributes_legal_form').val();
  if (!valuesWithoutBeneficialOwners.includes(value)) {
    beneficialOwnersRows.map(function() {
      $(this).removeClass('d-none');
    });
  } else if (valuesWithoutBeneficialOwners.includes(value)) {
    beneficialOwnersRows.map(function() {
      if (!$(this).hasClass('d-none')) {
        $(this).addClass('d-none');
      }
    });
  }
}

export function addBeneficialOwner() {
  var newBeneficialOwnerForm = $("#new-beneficial-owner-form").html()
  var idToReplace = parseInt($('#new-beneficial-owner-form :input')[0].id.match(/\d+/)[0])
  var newId = $(".beneficial-owner").length
  if (newId > 4) {
    alert($('.temp_information').attr('data'));
  } else {
    newBeneficialOwnerForm = newBeneficialOwnerForm.replace ( new RegExp(`${idToReplace}`, 'g'),`${newId}` )
    $("#fields").append(newBeneficialOwnerForm);
  }
  }

export function deleteBeneficialOwner() {
  $(this).closest('.beneficial-owner').remove();
}