import ScrollReveal from 'scrollreveal';

// funding request steps effect
$(document).ready(function() {
  ScrollReveal().reveal('.sr-step', { 
    reset: true, 
    opacity: .1,
    easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)',
    duration: 1200,
    viewOffset: { top: 200, right: 0, bottom: 200, left: 0 } 
  });
});
