export function displayMatrimonialRegimeFields() {
  var matrimonialFields = $('#matrimonial-regime');
  var value = $('#funding_application_user_marital_status').val();
  if (value == 'married') {
    matrimonialFields.removeClass('d-none');
  } else if (value !== 'married' && !matrimonialFields.hasClass('d-none')) {
    matrimonialFields.addClass('d-none');
    $('#funding_application_user_matrimonial_regime').prop('selectedIndex',0);
  }
}
